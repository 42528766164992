require('dotenv').config({
  path: `.env.${process.env.NODE_ENV}`,
});

const projectId = process.env.GATSBY_PROJECT_ID;
const dataset = process.env.GATSBY_DATASET;

export default {
  projectId: projectId,
  dataset: dataset,
};
